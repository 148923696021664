import $ from 'jquery';
import { validateInput, validateInputList } from './form/validation';
//import { initTMIdentityWidget } from './tmoauth/tmauth';
//import intlTelInput from './non-es6/intl-tel-input-master/build/js/intlTelInput.min';
// import { intlTelInput } from 'intl-tel-input';

import { initlistDisplayLookup } from './listdisplay/list-display-lookup';


//import { validate } from 'validate.js';
//import * as moment from 'moment';

$(function () {
	//Before using it we must add the parse and format functions
	//Here is a sample implementation using moment.js
	/*
	validate.extend(validate.validators.datetime, {
		// The value is guaranteed not to be null or undefined but otherwise it
		// could be anything.
		parse: function(value, options) {
		 return +moment.utc(value);
		},
		// Input is a unix timestamp
		format: function(value, options) {
		 var format = options.dateOnly ? "YYYY-MM-DD" : "YYYY-MM-DD hh:mm:ss";
		 return moment.utc(value).format(format);
		}
	});
	*/

  initlistDisplayLookup();
  //initTMIdentityWidget();


  const radioBtnList = document.querySelectorAll('input[type=radio]');
  radioBtnList.forEach(item => {
  	item.addEventListener('click', function() {
      //handle click
  		if(item.classList.contains('radioToggle')){
  			item.checked= false;
  		}
  		item.classList.toggle("radioToggle");

    })
  });

  const fileUploader = $('.js-fileUploader');
  const fileUploaded = $('.js-fileUploaded');


  fileUploader.change(function() {

     // validate file size:
    const sizeLimit = 50.00;  // Should be 5 MB

     // MB
    const thisFileSize = fileUploader[0].files[0].size;
    const uploadErrorSpan = $("#entry-upload");
    const uploadErrMsg = "The file you have uploaded has exceeded the maximum file size of 5MB.  Please select a smaller file and try again.";

    // console.log(thisFileSize);
    uploadErrorSpan.html('');

    if (thisFileSize > 0) {
      const calculatedSize = (thisFileSize / 1024 / 1024).toFixed(2);
      if (calculatedSize > sizeLimit) {
        // alert("File size too large: " + calculatedSize + "MB");
        uploadErrorSpan.html(uploadErrMsg);
        fileUploader.val('');
        return;
      }
    }
     // End: validate file size:

    const fileName = fileUploader.val().replace( '/.*(\/|\)/',  '');
    const fileInput = fileName.replace("C:\\fakepath\\", '');

    fileUploaded.val(fileInput);
  });

  $('.js-campaignForm input[type=tel]').intlTelInput({
    utilsScript: '/scripts/intTelInputUtils.js',
    preferredCountries: ['us', 'ca'],
    autoPlaceholder: 'off',
    excludeCountries: [
      'af', 'by', 'mm', 'cf', 'cn', 'cu', 'cy', 'cd', 'cg', 'er', 'ht', 'ir', 'iq', 'kg', 'lb', 'ly', 'kp', 'ru',
      'so', 'sd', 'sy', 've', 'zw'
    ]
  });

  $('.js-campaignForm input[type=tel]')
    .on('blur', function() {
      $(this).attr('data-is-dirty', true);
      validateInput($(this));
    })
    .on('keyup', function() {
      if ($(this).attr('data-is-dirty') === 'true') {
        validateInput($(this));
      }
    });

  const input = $(`.js-campaignForm input[type=text],
												 .js-campaignForm input[type=password],
												 .js-campaignForm input[type=email],
												 .js-campaignForm input[type=number],
												 .js-campaignForm textarea`);
  input.keyup(function(event) {
		// event.preventDefault();
    const thisInput =$( event.target );
    // console.log('this', thisInput);
		validateInputList(thisInput);
  })

  const options = $(`.js-campaignForm select,
                      .js-campaignForm input[type=checkbox]`);
  options.change(function(event) {
		// event.preventDefault();
    const thisInput =$( event.target );
    // console.log('this', thisInput);
		validateInputList(thisInput);
  });

	const submitForm = $('form.js-campaignForm');

	$(submitForm).submit(function( event ) {

		$('.js-campaignForm .inputError').html('');
		const inputList = $(`.js-campaignForm input[type=text],
													 .js-campaignForm input[type=password],
													 .js-campaignForm input[type=email],
													 .js-campaignForm input[type=number],
													 .js-campaignForm textarea,
	                         .js-campaignForm select,
	                         .js-campaignForm input[type=checkbox],
	                         .js-campaignForm input[type=tel]`);
			validateInputList(inputList);

	    const validationSatusList = $(`.js-campaignForm input[valid-input="false"],
													 .js-campaignForm textarea[valid-input="false"],
	                         .js-campaignForm select[valid-input="false"]`);

	    if (validationSatusList.length > 0) {
	      event.preventDefault();
	      // return false;
	    }
	    else {
	      $('.js-campaignForm input[type=tel]').each(function() {
	        $(this).val(
	          $(this).intlTelInput('getNumber')
	        );
	      });

	      if (fileUploader.length>0) {
	        const progressContainer = $(".uploadProgressContainer");
	        progressContainer.addClass("uploadProgressContainer--active");
	        $('.js-campaignForm').css("visibility", "hidden");
	      }
	    }
	    // event.preventDefault();   // test line



	    return true;
	});

});

import $ from 'jquery';

export function initlistDisplayLookup() {
	
	$('select').on('change', function() {
		var selectedDisplayText = $(this).find('option:selected').text();
		$("input[data-listdisplay-lookup='" + this.id +"']").val(selectedDisplayText);
	})
	
}

